import { Button, Layout, Modal, Input, Alert } from "antd";
import { Content } from "antd/lib/layout/layout";
import AddButton from "../components/AddButton";
import Header from "../components/Header";
import Sidebar from "../components/Sidebar";
import { useState, useEffect } from "react";
import { getToken } from "../services/TokenService";
import {
  createUser,
  suspendAccount,
  deleteUser,
  updatePassword,
} from "../services/UserService";
import { searchUsers } from "../services/FileService";
import Login from "./Login";
import {
  UserOutlined,
  EditOutlined,
  PlusOutlined,
  CloseOutlined,
} from "@ant-design/icons/lib/icons";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

const Users = () => {
  const [token, setToken] = useState(getToken());
  const [showCreateUser, setShowCreateUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [error, setError] = useState("");
  const [passError, setPassError] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [email, setEmail] = useState("");
  const [school, setSchool] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [term, setTerm] = useState("");
  const [user, setUser] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showSuspend, setShowSuspend] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const [users, setUsers] = useState([]);
  const [passwordChangeSucces, setPasswordChangeSuccess] = useState(false);
  const { t } = useTranslation("common");

  useEffect(() => {
    loadUsers();
  }, [term]);

  const addUserClick = () => {
    if (
      validateFields(fname) &&
      validateFields(lname) &&
      validateFields(email) &&
      validateFields(school) &&
      validateFields(password) &&
      validateFields(confirmPassword)
    ) {
      if (validateEmail(email)) {
        //create user here

        if (password === confirmPassword) {
          let response = createUserClick();
        } else {
          setPassError("Passwords do not match");
        }
      } else {
        setError("Email is invalid");
      }
    } else {
      setError("Please fill in all the fields");
    }
  };

  const createUserClick = async () => {
    let res = await createUser({
      username: fname + " " + lname,
      email: email,
      password: password,
      firstname: fname,
      lastname: lname,
      school: school,
    });

    if (res.success) {
      window.location.reload();
    } else {
      setError(res.result);
    }
  };

  const loadUsers = async () => {
    setLoading(true);
    let res = await searchUsers(term);
    if (res.success) {
      setUsers(res.result);
      setLoading(false);
    } else {
      setError(res.result);
    }
  };

  const handlePasswordUpdate = async () => {
    if (password === confirmPassword) {
      let res = await updatePassword(password, confirmPassword, user._id);
      if (res.success) {
        setPasswordChangeSuccess(true);
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        setPassError(t("forgotPassword.longer"));
      }
    } else {
      setPassError(t("forgotPassword.no-match"));
    }
  };

  const validateEmail = (email) => {
    let regex = /^\S+@\S+\.\S+$/;
    return regex.test(email);
  };

  const validateFields = (value) => {
    let regex = /\S/;
    return regex.test(value);
  };

  const handleSuspend = async () => {
    let res = await suspendAccount(user._id, !user.isSuspened);
    if (res.success) {
      window.location.reload();
    } else {
      // setError(res.result);
    }
  };

  const handleDelete = async () => {
    let res = await deleteUser(user._id);
    if (res.success) {
      window.location.reload();
    } else {
      // setError(res.result);
    }
  };

  return (
    <>
      {localStorage.getItem("remember") === "yes" || token ? (
        <Layout hasSider>
          <Sidebar parent={"Users"} />
          <Layout className="layout">
            <Header setUserTerm={setTerm} parent={"Users"} />
            <Content>
              {/* START MODAL FOR PASSWORD CHANGE SUCCESS  */}
              <Modal
                title=""
                visible={passwordChangeSucces}
                onCancel={() => {
                  setPasswordChangeSuccess(false);
                }}
                footer={null}
                style={{ marginTop: "30px" }}
              >
                {t("updatePassword.success")}
              </Modal>
              <div className="content-header">
                <h3>{t("sidebar.users")}</h3>
                <div onClick={() => setShowCreateUser(true)}>
                  <AddButton text={t("admin.addUser")} />
                </div>
              </div>
              {loading ? (
                <p>{t("explorePage.loading")}</p>
              ) : (
                <div className="grade-content ">
                  {users.map((user) => (
                    <div className="content-row user-content" key={user._id}>
                      <div className="user-title">
                        {user.avatar ? (
                          <img src={user.avatar} className="avatar" />
                        ) : (
                          <UserOutlined className="avatar" />
                        )}

                        <div className="user-details">
                          {user.foreName && user.lastName && (
                            <p>{user.foreName + " " + user.lastName}</p>
                          )}
                          <p className="email">{user.email}</p>
                        </div>
                        <div className="divider"></div>
                        <p className="created-by">
                          <span style={{ marginRight: 10 }}>
                            {t("admin.joinedOn")}
                          </span>
                          {user.createdAt ? (
                            <Moment format="MMM D YYYY">
                              {user.createdAt}
                            </Moment>
                          ) : undefined}
                        </p>
                      </div>
                      <div className="content-options">
                        {user.isSuspened ? (
                          <div className="flagged-tag ">
                            {" "}
                            {t("admin.suspended")}
                          </div>
                        ) : undefined}

                        <EditOutlined
                          onClick={() => {
                            setShowEditUser(true);
                            setUser(user);
                          }}
                        />
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Content>
          </Layout>
          {/* Create User Modal */}
          <Modal
            title=""
            visible={showCreateUser}
            // onOk={createNewGrade}
            onCancel={() => {
              setShowCreateUser(false);
              setError("");
            }}
            footer={null}
          >
            <h2 className="confirm-title"> {t("admin.addUser")}</h2>
            <p className="confirm-desc"> {t("admin.userDetails")}</p>
            <div className="form-name">
              <Input
                size="large"
                className="content-input"
                placeholder={t("profilePage.firstName")}
                onChange={(e) => {
                  setFName(e.target.value);
                  setError("");
                }}
              ></Input>
              <Input
                size="large"
                className="content-input"
                placeholder={t("profilePage.firstName")}
                style={{ marginLeft: 10 }}
                onChange={(e) => {
                  setLName(e.target.value);
                  setError("");
                }}
              ></Input>
            </div>
            <Input
              size="large"
              className="content-input"
              placeholder={t("profilePage.uni")}
              onChange={(e) => {
                setSchool(e.target.value);
                setError("");
              }}
            ></Input>
            <Input
              size="large"
              className="content-input"
              placeholder={t("profilePage.email")}
              onChange={(e) => {
                setEmail(e.target.value);
                setError("");
              }}
            ></Input>
            {error !== "" ? <p className="error">{error}</p> : undefined}

            <p className="security"> {t("admin.security")}</p>
            <Input.Password
              size="large"
              className="content-input"
              placeholder={t("profilePage.newPassword")}
              onChange={(e) => {
                setPassword(e.target.value);
                setPassError("");
              }}
            ></Input.Password>
            <Input.Password
              size="large"
              className="content-input"
              placeholder={t("profilePage.confirmNewPassword")}
              onChange={(e) => {
                setConfirmPassword(e.target.value);
                setPassError("");
              }}
            ></Input.Password>
            {passError !== "" ? (
              <p className="error">{passError}</p>
            ) : undefined}
            <Button type="primary add-user" onClick={addUserClick}>
              <PlusOutlined />
              {t("admin.addUser")}
            </Button>
          </Modal>
          {/* Edit User Modal */}
          <Modal
            title=""
            visible={showEditUser}
            // onOk={createNewGrade}
            onCancel={() => {
              setShowEditUser(false);
              setError("");
              setPassError("");
            }}
            footer={null}
          >
            <div className="user">
              {user.avatar ? (
                <img src={user.avatar} className="avatar" />
              ) : (
                <UserOutlined className="avatar" />
              )}
              <div>
                {user.foreName && user.lastName && (
                  <p>{user.foreName + " " + user.lastName}</p>
                )}
                <p className="created-by">
                  <span style={{ marginRight: 10 }}>{t("admin.joinedOn")}</span>
                  {user.createdAt ? (
                    <Moment format="MMM D YYYY">{user.createdAt}</Moment>
                  ) : undefined}
                </p>
              </div>
            </div>
            <div className="form-divider"></div>
            <div className="form-name">
              <p className="form-label">{t("profilePage.email")}</p>
              <p className="email"> {user.email}</p>
            </div>
            {/* <div className="form-name">
              <p className="form-label">{t("profilePage.userName")}</p>
              <p className="email"> {user.userName}</p>
            </div> */}
            <div className="form-name">
              <p className="form-label">{t("profilePage.uni")}</p>
              <p className="email"> {user.school}</p>
            </div>
            <div className="form-name">
              <p>{t("login.password")}</p>
              <div className="password-section">
                <Input.Password
                  size="large"
                  className="content-input"
                  placeholder={t("profilePage.newPassword")}
                  onChange={(e) => {
                    setPassword(e.target.value);
                    setError("");
                  }}
                ></Input.Password>
                <Input.Password
                  size="large"
                  className="content-input"
                  placeholder={t("profilePage.confirmNewPassword")}
                  onChange={(e) => {
                    setConfirmPassword(e.target.value);
                    setError("");
                  }}
                ></Input.Password>
                {passError !== "" ? (
                  <p className="error">{passError}</p>
                ) : undefined}
                <Button type="primary add-user" onClick={handlePasswordUpdate}>
                  <PlusOutlined />
                  {t("admin.changePassword")}
                </Button>
              </div>
            </div>
            <div className="form-divider"></div>

            {user._id !== "620e033ec6c8fb108ae41e99" ? (
              <div className="form-name">
                <p className="form-label">{t("profilePage.accountLink")}</p>
                <div>
                  {user.isSuspened ? (
                    <div>
                      <div className="suspend-div">
                        <div className="flagged-tag ">
                          {t("admin.suspended")}
                        </div>
                        <div
                          className="remove-suspension"
                          onClick={handleSuspend}
                        >
                          <CloseOutlined style={{ marginRight: 5 }} />
                          {t("admin.removeSuspend")}
                        </div>
                      </div>
                      <p className="error">
                        {t("admin.on")} {""}
                        <Moment format="MMM D YYYY">{new Date()}</Moment>
                      </p>
                      <p className="error">{t("admin.suspenddDesc")}</p>
                    </div>
                  ) : (
                    <div>
                      <h6
                        className="suspend-btn"
                        onClick={() => setShowSuspend(true)}
                      >
                        {t("admin.suspendBtn")}
                      </h6>
                      <p>{t("admin.suspendPrompt")}</p>
                    </div>
                  )}

                  <div>
                    <p
                      className="suspend-btn delete-btn"
                      onClick={() => setShowDelete(true)}
                    >
                      {t("profilePage.deleteAccount")}
                    </p>
                    <p>{t("admin.deleteConfirm")}</p>
                  </div>
                </div>
              </div>
            ) : undefined}
          </Modal>
          {/* Confirm Suspend Modal */}
          <Modal
            title=""
            visible={showSuspend}
            onOk={handleSuspend}
            onCancel={() => setShowSuspend(false)}
            okText={"Suspend"}
            okButtonProps={{
              style: {
                backgroundColor: "#C0182C",
                border: "none",
                borderRadius: "3px",
              },
            }}
          >
            <h2 className="confirm-title">{t("admin.suspendConfirm")}</h2>
            <p className="confirm-desc">{t("admin.suspendPrompt")}</p>
          </Modal>
          {/* Confirm Delete Modal */}
          <Modal
            title=""
            visible={showDelete}
            onCancel={() => {
              setShowDelete(false);
              setDeleteText("");
            }}
            footer={null}
            width={400}
            style={{ marginTop: "100px" }}
          >
            <h2 className="confirm-title">{t("myContentPage.delete")}?</h2>

            <p>
              {t("admin.deleteTheAccount")} -{" "}
              {user.foreName + " " + user.lastName} {t("admin.deleteCont")}
            </p>

            <p className="confirm-userdelete">{t("admin.deleteCheck")}</p>
            <div className="user-delete">
              <Input onChange={(e) => setDeleteText(e.target.value)} />
              <Button
                type="danger"
                style={{
                  backgroundColor: "#C0182C",
                  border: "none",
                  borderRadius: "3px",
                }}
                onClick={() => handleDelete()}
                disabled={deleteText === "DELETE" ? false : true}
              >
                {t("myContentPage.delete")}
              </Button>
            </div>

            <Button
              style={{
                borderRadius: "3px",
                marginTop: 30,
                width: "100%",
              }}
              onClick={() => {
                setShowDelete(false);
                setDeleteText("");
              }}
            >
              {t("sidebar.cancel")}
            </Button>
          </Modal>
        </Layout>
      ) : (
        <Login />
      )}
    </>
  );
};

export default Users;
