import { Input, Button, Dropdown, Menu } from "antd";
import { SearchOutlined, SelectOutlined } from "@ant-design/icons/lib/icons";
import "./header.css";
import { getCurrentUserDetails } from "../services/UserService";
import { useState, useEffect } from "react";
import { setToken } from "../services/TokenService";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const {
    parent,
    setUserTerm,
    setGradeTerm,
    setFlaggedTerm,
    setSubjectTerm,
    setSemesterTerm,
  } = props;
  const [user, setUser] = useState([]);
  const [userSearchTerm, setUserSearchTerm] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const getUserDetails = async () => {
    let details = await getCurrentUserDetails();
    setUser(details.result);
  };

  const logout = () => {
    setToken("");
    localStorage.clear();
    navigate("/login");
  };

  const search = () => {
    if (parent === "Users") {
      setUserTerm(userSearchTerm);
    } else if (parent === "Grades") {
      setGradeTerm(userSearchTerm);
    } else if (parent === "Flagged") {
      setFlaggedTerm(userSearchTerm);
    } else if (parent === "Subjects") {
      setSubjectTerm(userSearchTerm);
    } else if (parent === "Semesters") {
      setSemesterTerm(userSearchTerm);
    }
  };

  const handleKey = (event) => {
    if (event.key === "Enter") {
      search();
    }
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  const menu = (
    <Menu className="logout-menu">
      <Menu.Item key="1" onClick={logout}>
        <div className="logout">
          {t("profilePage.signOut")}
          <SelectOutlined />
        </div>
      </Menu.Item>
      <p>{t("profilePage.language")}</p>
      <div className="lan-options">
        <p
          className={i18n.language === "en" ? "selected-language" : ""}
          onClick={() => {
            i18n.changeLanguage("en");
            localStorage.setItem("language", "en");
          }}
        >
          EN
        </p>
        <p
          className={i18n.language === "ko" ? "selected-language" : ""}
          onClick={() => {
            i18n.changeLanguage("ko");
            localStorage.setItem("language", "ko");
          }}
        >
          KO
        </p>
      </div>
    </Menu>
  );

  return (
    <div className="header">
      <Input
        placeholder={t("filters.search")}
        className="search-input"
        prefix={<SearchOutlined />}
        onChange={(e) => setUserSearchTerm(e.target.value)}
        onKeyPress={(e) => handleKey(e)}
      />
      <Button type="primary search-btn" onClick={search}>
        {t("filters.search")}
      </Button>
      {user.avatar ? (
        <Dropdown.Button
          className="logout-dropdown"
          icon={<img src={user.avatar} className="user-icon" />}
          // onVisibleChange={() => setSelectedContent(content.title)}
          overlay={menu}
        ></Dropdown.Button>
      ) : (
        <Dropdown.Button
          className="logout-dropdown"
          // icon={<img src={user.avatar} className="user-icon" />}
          // onVisibleChange={() => setSelectedContent(content.title)}
          overlay={menu}
        ></Dropdown.Button>
      )}
    </div>
  );
};

export default Header;
