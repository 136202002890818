const backendUrl = process.env.REACT_APP_BACKED_BASE_URL_USER;

/* USERS */
export async function searchUsers(searchTerm) {
  const body = JSON.stringify({ searchTerm: searchTerm });
  const result = await fetch(`${backendUrl}/api/user/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

/* GRADES */
export async function getAllGrades() {
  const result = await fetch(`${backendUrl}/api/category/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function searchGrades(searchTerm) {
  const body = JSON.stringify({ searchTerm: searchTerm });
  const result = await fetch(`${backendUrl}/api/category/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function createGrade(name) {
  const body = JSON.stringify({ name: name });
  const result = await fetch(`${backendUrl}/api/category`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function deleteGrade(id) {
  const body = JSON.stringify({ id: id, delete: true });
  const result = await fetch(`${backendUrl}/api/category/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function renameGrade(id, name) {
  const body = JSON.stringify({ id: id, category_name: name });
  const result = await fetch(`${backendUrl}/api/category/rename`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

/* SEMESTERS */
export async function getAllSemesters() {
  const result = await fetch(`${backendUrl}/api/semester/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function createSemester(name) {
  const body = JSON.stringify({ semester_name: name });
  const result = await fetch(`${backendUrl}/api/semester`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

/** FLAGGED CONTENT */
export async function searchSharedContent(searchTerm, page) {
  const token = JSON.stringify(localStorage.getItem("token"));
  const body = JSON.stringify({
    categoryIds: null,
    subjectId: [],
    search_terms: searchTerm,
    sort: "title",
    path: "sharedContent",
    page: page,
    data: 12,
    order: 1,
    contentType: "all",
    grade: null,
    semester: [],
  });
  const result = await fetch(`${backendUrl}/api/content/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      secret_token: token,
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}
export async function flagContent(id, isFlagged) {
  const body = JSON.stringify({ id: id, flag: isFlagged });
  const result = await fetch(`${backendUrl}/api/flagContent`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function searchFlagged(searchTerm) {
  const body = JSON.stringify({ searchTerm: searchTerm });
  const result = await fetch(`${backendUrl}/api/flagContent/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

//subjects
export async function searchSubjects(searchTerm) {
  const body = JSON.stringify({ searchTerm: searchTerm });
  const result = await fetch(`${backendUrl}/api/subject/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function createSubject(name) {
  const body = JSON.stringify({ name: name });
  const result = await fetch(`${backendUrl}/api/subject`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function renameSubject(id, name) {
  const body = JSON.stringify({ id: id, name: name });
  const result = await fetch(`${backendUrl}/api/subject/rename`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function deleteSubject(id) {
  const body = JSON.stringify({ id: id, delete: true });
  const result = await fetch(`${backendUrl}/api/subject/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

//semesters
export async function searchSemesters(searchTerm) {
  const body = JSON.stringify({ searchTerm: searchTerm });
  const result = await fetch(`${backendUrl}/api/semesters/search`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function renameSemester(id, name) {
  const body = JSON.stringify({ id: id, name: name });
  const result = await fetch(`${backendUrl}/api/semester/rename`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function deleteSemester(id) {
  const body = JSON.stringify({ id: id, delete: true });
  const result = await fetch(`${backendUrl}/api/semester/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

//subgrades
export async function getAllSubGrades() {
  const result = await fetch(`${backendUrl}/api/grades/all`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function createSubgrade(name, category) {
  const body = JSON.stringify({ category: category, grade_name: name });
  const result = await fetch(`${backendUrl}/api/grade`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function deleteSubGrade(id) {
  const body = JSON.stringify({ id: id, delete: true });
  const result = await fetch(`${backendUrl}/api/grade/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function renameSubgrade(id, name) {
  const body = JSON.stringify({ id: id, name: name });
  const result = await fetch(`${backendUrl}/api/subgrade/rename`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}
