const baseUrl_Backend = process.env.REACT_APP_BACKED_BASE_URL_USER;
export async function login(credentials) {
  return fetch(baseUrl_Backend + "/api/admin/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },

    body: JSON.stringify({
      password: credentials.password,
      email: credentials.username,
    }),
  }).then((data) => data.json());
}

export function register(user) {
  return fetch(baseUrl_Backend + "/api/auth/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: user.email,
      password: user.password,
      userName: user.username,
      foreName: user.firstName,
      lastName: user.lastName,
      school: user.school,
    }),
  }).then((data) => data.json());
}

export function validateToken(emailToken) {
  return fetch(baseUrl_Backend + "/api/validateToken", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: emailToken,
    }),
  }).then((data) => data.json());
}

export function resetPassword(token, password, confirmPassword) {
  return fetch(baseUrl_Backend + "/api/resetPassword", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      token: token,
      pwd1: password,
      pwd2: confirmPassword,
    }),
  }).then((data) => data.json());
}
export function restEmail(email) {
  return fetch(baseUrl_Backend + "/api/sentMail", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
    }),
  }).then((data) => data.json());
}
