import { Layout, Dropdown, Menu, Modal, Input, Button, Pagination } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import AddButton from "../components/AddButton";
import React, { useState, useEffect } from "react";
import {
  EditOutlined,
  CloseOutlined,
  FlagOutlined,
  SearchOutlined,
  PlusOutlined,
  EyeOutlined,
} from "@ant-design/icons/lib/icons";
import { getToken } from "../services/TokenService";
import Login from "./Login";
import img from "../assets/bg-login.jpg";
import Moment from "react-moment";
import {
  searchSharedContent,
  flagContent,
  searchFlagged,
} from "../services/FileService";
import { useTranslation } from "react-i18next";

const Flagged = () => {
  const [flaggedContent, setFlaggedContent] = useState([]);
  const [token, setToken] = useState(getToken());
  const [showConfirmRemove, setShowConfirmRemove] = useState(false);
  const [showSelect, setShowSelect] = useState(false);
  const [selectedContent, setSelectedContent] = useState("");
  const [sharedContent, setSharedContent] = useState([]);
  const [showConfirmFlag, setShowConfirmFlag] = useState(false);
  const [confirmFlagContent, setConfirmFlagContent] = useState("");
  const [confirmFlagTitle, setConfirmFlagTitle] = useState("");
  const [addFlag, setAddFlag] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [flagSearchTerm, setFlagSearchTerm] = useState("");
  const [loading, setLoading] = useState(true);
  const [url, setUrl] = useState("");
  const [pages, setPages] = useState("");
  const [page, setPage] = useState("");
  const frontendUrl = process.env.REACT_APP_CMS_BASE_URL;
  const { t } = useTranslation("common");

  useEffect(() => {
    searchContent();
  }, [page]);

  useEffect(() => {
    searchFlaggedContent();
  }, [flagSearchTerm]);

  const searchContent = async () => {
    let response = await searchSharedContent(searchTerm, page);
    if (response.success) {
      setSharedContent(response.results);
      setPages(response.pages);
    }
  };

  const searchFlaggedContent = async () => {
    setLoading(true);
    let response = await searchFlagged("");
    if (response.success) {
      setFlaggedContent(response.result);
    }
    setLoading(false);
  };

  const handleFlag = async () => {
    let response = await flagContent(selectedContent, addFlag);
    if (response.success) {
      window.location.reload();
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      searchContent();
    }
  };

  const preview = (id) => {
    window.open(frontendUrl + "/video/" + id, "_blank");
  };

  const menu = (
    <Menu>
      <Menu.Item
        key="1"
        onClick={() => {
          setAddFlag(false);
          setShowConfirmRemove(true);
          setConfirmFlagContent(t("admin.unflagDesc"));
          setConfirmFlagTitle(t("admin.unflagCheck"));
        }}
      >
        {t("admin.unflag")}
      </Menu.Item>
    </Menu>
  );

  const onChangePage = (value) => {
    setPage(value - 1);
  };

  const generatePagination = () => {
    return (
      <Pagination
        defaultPageSize={12}
        onChange={(value) => onChangePage(value)}
        defaultCurrent={1}
        total={pages}
      />
    );
  };

  useEffect(() => {
    generatePagination();
  }, [pages]);

  return (
    <>
      {localStorage.getItem("remember") === "yes" || token ? (
        <Layout hasSider>
          <Sidebar parent={"Flagged"} />
          <Layout className="layout">
            <Header parent={"Flagged"} setFlaggedTerm={setFlagSearchTerm} />
            {/* Confirm Unflag Modal */}
            <Modal
              title=""
              visible={showConfirmRemove}
              cancelText={t("sidebar.cancel")}
              onOk={handleFlag}
              onCancel={() => setShowConfirmRemove(false)}
              okText={addFlag ? t("admin.flag") : t("admin.unflag")}
            >
              <h2 className="confirm-title">{confirmFlagTitle}</h2>

              <p className="confirm-desc">{confirmFlagContent}</p>
            </Modal>
            <Content>
              <div className="content-header">
                <h3>{t("sidebar.flaggedContent")}</h3>
                <div onClick={() => setShowSelect(true)}>
                  <AddButton text={t("weetConPage.addContent")} />
                </div>
              </div>
              <p className="flagged-desc">{t("admin.flagDesc")}</p>
              <div className="grade-content">
                {loading ? (
                  <p>{t("explorePage.loading")}</p>
                ) : (
                  <>
                    {flaggedContent.map((content, index) => (
                      <div className="flagged-row" key={index}>
                        <div className="flagged-title">
                          <FlagOutlined style={{ marginRight: "10px" }} />
                          {content.thumbnail ? (
                            <img src={content.thumbnail} />
                          ) : (
                            <img src={img} />
                          )}
                          <p>{content.title}</p>
                          {content.contentType === "library" ? (
                            <div className="flagged-tag library-tag">
                              {t("filters.library")}
                            </div>
                          ) : (
                            <div className="flagged-tag">
                              {t("filters.weetCon")}
                            </div>
                          )}
                        </div>
                        <p className="created-by">
                          {content.user.length > 0 ? (
                            <>
                              <span>{t("admin.createdBy")}</span>

                              {content.user[0].foreName +
                                " " +
                                content.user[0].lastName}
                            </>
                          ) : undefined}
                        </p>
                        <div className="flagged-options">
                          <p className="created-by">
                            <span style={{ marginRight: 10 }}>
                              {t("admin.flaggedOn")}
                            </span>
                            <Moment format="MMM D YYYY">{content.date}</Moment>
                          </p>
                          <Dropdown.Button
                            className="flagged-dropdown"
                            onVisibleChange={() =>
                              setSelectedContent(content._id)
                            }
                            overlay={menu}
                          ></Dropdown.Button>
                        </div>
                      </div>
                    ))}
                  </>
                )}
              </div>
            </Content>
          </Layout>
        </Layout>
      ) : (
        <Login />
      )}
      {/* Select Content Modal */}
      <Modal
        title=""
        visible={showSelect}
        onCancel={() => setShowSelect(false)}
        width={1500}
        cancelText={t("sidebar.cancel")}
        footer={null}
      >
        <h2 className="confirm-title">{t("admin.selectFlag")}</h2>
        <div className="search">
          <Input
            placeholder={t("filters.search")}
            className="flagged-search"
            prefix={<SearchOutlined />}
            onChange={(e) => setSearchTerm(e.target.value)}
            onPressEnter={(e) => handleKeyPress(e)}
          />
          <Button type="primary search-btn" onClick={searchContent}>
            {t("filters.search")}
          </Button>
        </div>

        <div className="grade-content">
          {sharedContent.map((content, index) => (
            <div className="flagged-row" key={index}>
              <div className="flagged-title">
                <FlagOutlined style={{ marginRight: "10px" }} />
                {content.thumbnail ? (
                  <img src={content.thumbnail} />
                ) : (
                  <img src={img} />
                )}
                <p>{content.title}</p>
                {content.contentType === "library" ? (
                  <div className="flagged-tag library-tag">
                    {t("filters.library")}
                  </div>
                ) : (
                  <div className="flagged-tag">{t("filters.weetCon")}</div>
                )}
              </div>
              <p className="created-by">
                <span>{t("admin.createdBy")}</span>
                {content.user.foreName + " " + content.user.lastName}
              </p>
              <div className="flagged-options">
                <p className="created-by">
                  <span style={{ marginRight: 10 }}>
                    {t("admin.publishedOn")}{" "}
                  </span>
                  <Moment format="MMM D YYYY">{content.date}</Moment>
                </p>
                <PlusOutlined
                  className="add-flag"
                  onClick={() => {
                    setSelectedContent(content._id);
                    setAddFlag(true);
                    setConfirmFlagTitle(t("admin.flagPrompt"));
                    setConfirmFlagContent(t("admin.flagConfirm"));
                    setShowConfirmRemove(true);
                  }}
                />
                <EyeOutlined
                  onClick={() => {
                    setSelectedContent(content._id);
                    preview(content._id);
                  }}
                />
                {/* <Dropdown.Button
                  className="flagged-dropdown"
                  onVisibleChange={() => setSelectedContent(content.title)}
                  overlay={menu}
                ></Dropdown.Button> */}
              </div>
            </div>
          ))}
          <div className="pagination">{generatePagination()}</div>
        </div>
      </Modal>
    </>
  );
};

export default Flagged;
