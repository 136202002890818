import { Button } from "antd";
import { PlusOutlined } from "@ant-design/icons/lib/icons";
import "./addButton.css";

const AddButton = (props) => {
  const { text } = props;
  return (
    <Button type="primary add-btn">
      <PlusOutlined />
      {text}
    </Button>
  );
};

export default AddButton;
