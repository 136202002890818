import React, { useState } from "react";
import logo from "../assets/smallLogo.png";
import { Row, Col, Input, Dropdown, Button, Menu, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons/lib/icons";
import { restEmail } from "../services/AuthService";
import { login } from "../services/AuthService";
import { setToken } from "../services/TokenService";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateEmail(email)) {
      const result = await restEmail(email);
      if (result.success) {
        setShowModal(true);
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setError(t("forgotPassword.invalid"));
      }
    } else {
      setError(t("filters.emailInvalid"));
    }
  };

  const validateEmail = (email) => {
    return email.match(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );
  };

  const menu = (
    <Menu className="logout-menu">
      <Menu.Item key="1">
        <div className="logout" onClick={() => i18n.changeLanguage("en")}>
          {t("profilePage.english")}
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div className="logout" onClick={() => i18n.changeLanguage("ko")}>
          {t("profilePage.korean")}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="Login"
      style={{
        height: "100vh",
        textAlign: "left",
      }}
    >
      <Row>
        <Col span={17} className="login-bg">
          <h1>Share your knowledge.</h1>
        </Col>
        <Col span={7} className="login-form">
          <div>
            <div className="login-header">
              <img
                src={logo}
                alt="logo"
                data-src="/assets/img/cms/smallLogo.png"
                data-src-retina="/assets/img/cms/smallLogo.png"
                width="30"
                height="30"
              />
              <h4
                className="p-l-15 m-0"
                style={{ fontSize: "14px", paddingLeft: "15px" }}
              >
                CMS ADMIN
              </h4>
            </div>
            <h2> {t("forgotPassword.prompt")}</h2>
            <p>{t("forgotPassword.desc")}</p>
            <form
              id="form-login"
              role="form"
              onSubmit={handleSubmit}
              method="POST"
            >
              {error !== "" ? (
                <p style={{ marginBottom: "0px", color: "red" }}>{error}</p>
              ) : (
                ""
              )}

              <div className="controls">
                <Input
                  type="text"
                  name="email"
                  placeholder={t("profilePage.email")}
                  className="form-control"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>

              <Button type="primary search-btn signin-btn" htmlType="submit">
                {t("forgotPassword.submit")}
              </Button>
            </form>

            <Dropdown overlay={menu} className="login-dropdown">
              <Button>
                {t("profilePage.language")} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <p className="trademark">
            {t("login.trademark")}
            {/* ©2019-2020 All Rights Reserved. Pages® is a registered trademark of
            Revox Ltd. <a href="">Cookie Policy</a>,{" "}
            <a href=""> Privacy and Terms</a>. */}
          </p>
        </Col>
      </Row>
      <Modal
        show={showModal}
        hide={() => {
          setShowModal(false);
        }}
        centered
      >
        <Modal.Body className="text-center m-t-20">
          {t("forgotPassword.success")}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ForgotPassword;
