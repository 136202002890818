import { Layout, Menu } from "antd";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo-white.png";
import "./sidebar.css";
import {
  UserOutlined,
  TagOutlined,
  FlagOutlined,
  ExperimentOutlined,
  CalendarOutlined,
} from "@ant-design/icons/lib/icons";
import { useTranslation } from "react-i18next";
const { Header, Content, Footer, Sider } = Layout;

const Sidebar = (props) => {
  const { parent, breakpoint, collapsedWidth } = props;
  const { t } = useTranslation("common");
  const navigate = useNavigate();

  return (
    <Sider
      breakpoint={breakpoint}
      collapsedWidth={collapsedWidth}
      onBreakpoint={(broken) => {
        console.log(broken);
      }}
      onCollapse={(collapsed, type) => {
        console.log(collapsed, type);
      }}
    >
      <Link to="/">
        <img src={logo} />
      </Link>
      <p className="light-text capitalize">{t("sidebar.adminTools")}</p>
      <Menu theme="dark" mode="inline" className="sider-menu">
        <Menu.Item
          key="1"
          className={
            parent === "Users" ? "sidebar-item active" : "sidebar-item"
          }
        >
          <Link to="/">
            <UserOutlined style={{ marginRight: "7px" }} />
            {t("sidebar.users")}
          </Link>
        </Menu.Item>
        <Menu.Item
          key="2"
          className={
            parent === "Grades" ? "sidebar-item active" : "sidebar-item"
          }
        >
          <Link to="/grades">
            <TagOutlined style={{ marginRight: "7px" }} />
            {t("sidebar.grades")}
          </Link>
        </Menu.Item>
        <Menu.Item
          key="5"
          className={
            parent === "Subjects" ? "sidebar-item active" : "sidebar-item"
          }
        >
          <Link to="/subjects">
            <ExperimentOutlined style={{ marginRight: "7px" }} />
            {t("sidebar.subjects")}
          </Link>
        </Menu.Item>
        <Menu.Item
          key="3"
          className={
            parent === "Semesters" ? "sidebar-item active" : "sidebar-item"
          }
        >
          <Link to="/semesters">
            <CalendarOutlined style={{ marginRight: "7px" }} />
            {t("sidebar.semesters")}
          </Link>
        </Menu.Item>
        <Menu.Item
          key="4"
          className={
            parent === "Flagged" ? "sidebar-item active" : "sidebar-item"
          }
        >
          <Link to="/flagged">
            <FlagOutlined style={{ marginRight: "7px" }} />
            {t("sidebar.flaggedContent")}
          </Link>
        </Menu.Item>
      </Menu>
    </Sider>
  );
};

export default Sidebar;
