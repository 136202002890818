import { Layout, Modal, Button, Input } from "antd";
import { Content } from "antd/lib/layout/layout";
import Sidebar from "../components/Sidebar";
import Header from "../components/Header";
import AddButton from "../components/AddButton";
import Login from "./Login";
import React, { useState, useEffect } from "react";
import {
  EditOutlined,
  CloseOutlined,
  PlusOutlined,
} from "@ant-design/icons/lib/icons";
import { getToken } from "../services/TokenService";
import {
  deleteGrade,
  createGrade,
  searchGrades,
  renameGrade,
  getAllSubGrades,
  createSubgrade,
  deleteSubGrade,
  renameSubgrade,
} from "../services/FileService";
import { useTranslation } from "react-i18next";

const Grades = () => {
  const [loading, setLoading] = useState(true);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showAddGrade, setShowAddGrade] = useState(false);
  const [showRenameGrade, setShowRenameGrade] = useState(false);
  const [showAddSubgrade, setShowAddSubgrade] = useState(false);
  const [token, setToken] = useState(getToken());
  const [newGrade, setNewGrade] = useState("");
  const [error, setError] = useState("");
  const [selectedGrade, setSelectedGrade] = useState("");
  const [selectedGradeName, setSelectedGradeName] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [subgrades, setSubGrades] = useState([]);
  const [currentlyEditing, setCurrentlyEditing] = useState([]);
  const [isSubgradeEdit, setIsSubgradeEdit] = useState(false);
  const [grades, setGrades] = useState([]);

  const { t } = useTranslation("common");

  useEffect(() => {
    getGrades();
    getSubGrades();
  }, [searchTerm]);

  const getGrades = async () => {
    let grades = await searchGrades(searchTerm);

    setGrades(grades.result);
    setLoading(false);
  };

  const createNewGrade = async () => {
    if (newGrade === "") {
      setError("Please enter a name");
    } else {
      let response = await createGrade(newGrade);
      setShowAddGrade(false);
      setNewGrade("");
      window.location.reload();
    }
  };

  const deleteSelectedGrade = async () => {
    let response;
    if (!isSubgradeEdit) {
      response = await deleteGrade(selectedGrade);
    } else {
      response = await deleteSubGrade(selectedGrade);
    }
    if (!response.success) {
      setError(response.message);
    } else {
      setShowConfirmDelete(false);
      window.location.reload();
    }
  };

  const renameSelectedGrade = async () => {
    let response;
    if (!isSubgradeEdit) {
      response = await renameGrade(selectedGrade, newGrade);
    } else {
      response = await renameSubgrade(selectedGrade, newGrade);
    }
    if (!response.success) {
      setError(response.message);
    } else {
      setShowRenameGrade(false);
      window.location.reload();
    }
  };

  const getSubGrades = async () => {
    let response = await getAllSubGrades();
    if (!response.success) {
      setError(response.message);
    } else {
      setSubGrades(response.result);
    }
  };

  const createSubGrade = async () => {
    if (newGrade === "") {
      setError("Please enter a name");
    } else {
      let response = await createSubgrade(newGrade, selectedGrade);
      if (response.success) {
        setShowAddSubgrade(false);
        setNewGrade("");
        window.location.reload();
      } else {
        setError(response.result);
      }
    }
  };

  return (
    <div>
      {localStorage.getItem("remember") === "yes" || token ? (
        <Layout hasSider>
          <Sidebar parent={"Grades"} breakpoint={"lg"} collapsedWidth={0} />
          <Layout className="layout">
            <Header setGradeTerm={setSearchTerm} parent={"Grades"} />
            <Content>
              <div className="content-header">
                <h3> {t("filters.grade")}</h3>
                <div onClick={() => setShowAddGrade(true)}>
                  <AddButton text={t("admin.addGrade")} />
                </div>
              </div>
              {loading ? (
                <p> {t("explorePage.loading")}</p>
              ) : (
                <div className="grade-content">
                  {grades.map((grade) => (
                    <div>
                      <div className="content-row" key={grade._id}>
                        <p>{grade.category_name}</p>
                        <div className="content-options">
                          <p
                            className="subgrade-btn"
                            onClick={() => {
                              setSelectedGradeName(grade.category_name);
                              setShowAddSubgrade(true);
                              setSelectedGrade(grade._id);
                            }}
                          >
                            <PlusOutlined />
                            {t("sidebar.subgrades")}
                          </p>
                          <EditOutlined
                            onClick={() => {
                              setCurrentlyEditing(grade);
                              setShowRenameGrade(true);
                              setSelectedGrade(grade._id);
                            }}
                          />
                          <div className="divider"></div>
                          <CloseOutlined
                            onClick={() => {
                              setShowConfirmDelete(true);
                              setSelectedGrade(grade._id);
                            }}
                          />
                        </div>
                      </div>
                      <div className="subgrade-div">
                        {subgrades
                          .filter(
                            (subgrades) => subgrades.category === grade._id
                          )
                          .map((subgrade) => (
                            <div
                              className="content-row subgrade-row"
                              key={subgrade._id}
                            >
                              <p>{subgrade.grade_name}</p>
                              <div className="content-options">
                                <EditOutlined
                                  onClick={() => {
                                    setCurrentlyEditing(subgrade);
                                    setIsSubgradeEdit(true);
                                    setShowRenameGrade(true);
                                    setSelectedGrade(subgrade._id);
                                  }}
                                />
                                <div className="divider"></div>
                                <CloseOutlined
                                  onClick={() => {
                                    setShowConfirmDelete(true);
                                    setSelectedGrade(subgrade._id);
                                    setIsSubgradeEdit(true);
                                  }}
                                />
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </Content>
          </Layout>
          {/* Confirm Delete Modal */}
          <Modal
            title=""
            visible={showConfirmDelete}
            onOk={() => deleteSelectedGrade()}
            onCancel={() => {
              setShowConfirmDelete(false);
              setError("");
            }}
            cancelText={t("sidebar.cancel")}
            okText={t("myContentPage.delete")}
            okButtonProps={{
              style: {
                backgroundColor: "#C0182C",
                border: "none",
                borderRadius: "3px",
              },
            }}
          >
            <h2 className="confirm-title">{t("admin.deleteSectionPrompt")}</h2>

            <p className="confirm-desc">{t("profilePage.deleteDesc")}</p>

            {error !== "" ? <p className="error">{error}</p> : undefined}
          </Modal>
          {/* Add Grade Modal */}
          <Modal
            title=""
            visible={showAddGrade}
            onOk={createNewGrade}
            cancelText={t("sidebar.cancel")}
            onCancel={() => {
              setShowAddGrade(false);
              setError("");
            }}
            okText={t("weetConPage.add")}
            okButtonProps={{
              style: {
                border: "none",
                borderRadius: "3px",
              },
            }}
          >
            <h2 className="confirm-title">{t("admin.addGrade")}</h2>
            <p className="confirm-desc">
              {t("admin.enter")} {t("filters.grade")}
            </p>
            <Input
              className="content-input"
              onChange={(e) => {
                setNewGrade(e.target.value);
                setError("");
              }}
            ></Input>
            {error !== "" ? <p className="error">{error}</p> : undefined}
          </Modal>
          {/* Rename Grade Modal */}
          <Modal
            title=""
            key={isSubgradeEdit}
            visible={showRenameGrade}
            onOk={renameSelectedGrade}
            cancelText={t("sidebar.cancel")}
            onCancel={() => {
              setShowRenameGrade(false);
              setError("");
              setIsSubgradeEdit(false);
            }}
            okText={t("myContentPage.rename")}
            okButtonProps={{
              style: {
                border: "none",
                borderRadius: "3px",
              },
            }}
          >
            <h2 className="confirm-title">{t("myContentPage.rename")}</h2>
            <p className="confirm-desc">{t("admin.enterNew")}:</p>
            <Input
              className="content-input"
              defaultValue={
                isSubgradeEdit
                  ? currentlyEditing.grade_name
                  : currentlyEditing.category_name
              }
              onChange={(e) => {
                setNewGrade(e.target.value);
                setError("");
              }}
            ></Input>
            {error !== "" ? <p className="error">{error}</p> : undefined}
          </Modal>
          {/* Add subgrade modal */}
          <Modal
            title=""
            visible={showAddSubgrade}
            onOk={createSubGrade}
            cancelText={t("sidebar.cancel")}
            onCancel={() => {
              setShowAddSubgrade(false);
              setError("");
            }}
            okText={t("weetConPage.add")}
            okButtonProps={{
              style: {
                border: "none",
                borderRadius: "3px",
              },
            }}
          >
            <h2 className="confirm-title">
              {t("weetConPage.add")}
              {t("sidebar.subgrades")}
            </h2>
            <p className="confirm-desc">
              {t("admin.enterNew")} {t("sidebar.subgrades")}
            </p>
            <div className="content-row grade-name">{selectedGradeName}</div>
            <div>
              <div className="subgrade-line"></div>
              <div className="subgrade-line horizontal"></div>
              <div className="sub-div">
                <Input
                  className="content-input subgrade-input "
                  onChange={(e) => {
                    setNewGrade(e.target.value);
                    setError("");
                  }}
                ></Input>
              </div>
            </div>
            {error !== "" ? <p className="error">{error}</p> : undefined}
          </Modal>
        </Layout>
      ) : (
        <Login />
      )}
    </div>
  );
};

export default Grades;
