const baseUrl = process.env.REACT_APP_BACKED_BASE_URL_USER;
export async function getCurrentUserDetails() {
  const token = JSON.stringify(localStorage.getItem("token"));
  const result = await fetch(baseUrl + `/api/user/account/details`, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      secret_token: token,
    },
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function createUser(user) {
  return fetch(baseUrl + "/api/auth/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email: user.email,
      password: user.password,
      userName: user.username,
      foreName: user.firstName,
      lastName: user.lastName,
      school: user.school,
    }),
  }).then((data) => data.json());
}

export async function chnageAvatar(file) {
  const token = JSON.stringify(localStorage.getItem("token"));
  const formData = new FormData();
  formData.append("file", file);
  const result = await fetch(baseUrl + `/api/user/avatar/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      secret_token: token,
    },
    body: formData,
  });

  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function updatePassword(newPassword, confirmPassword, id) {
  const body = JSON.stringify({
    pwd1: newPassword,
    pwd2: confirmPassword,
    userId: id,
  });
  const result = await fetch(baseUrl + `/api/user/changePassworByAdmin`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });
  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function suspendAccount(id, suspend) {
  const body = JSON.stringify({ userId: id, suspend: suspend });
  const result = await fetch(baseUrl + `/api/user/suspend`, {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });

  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}

export async function deleteUser(id) {
  const body = JSON.stringify({ userId: id, delete: true });
  const result = await fetch(baseUrl + `/api/user/delete`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body,
  });

  if (result.ok) {
    return result.json();
  }
  throw new Error(
    `Request to REST endpoint returned ${result.status} ${
      result.statusText
    }: ${await result.text()}`
  );
}
