import "./App.less";
import { Route, BrowserRouter, Routes, HashRouter } from "react-router-dom";
import Login from "./screens/Login";
import Register from "./screens/Register";
import Users from "./screens/Users";
import Grades from "./screens/Grades";
import Flagged from "./screens/Flagged";
import Semesters from "./screens/Semesters";
import ForgotPassword from "./screens/ForgotPassword";
import ResetPassword from "./screens/ResetPassword";
import Subjects from "./screens/Subjects";

function App() {
  console.log = console.warn = console.error = () => {};
  return (
    <div className="App">
      <HashRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/" element={<Users />} />
          <Route path="/grades" element={<Grades />} />
          <Route path="/subjects" element={<Subjects />} />
          <Route path="/flagged" element={<Flagged />} />
          <Route path="/semesters" element={<Semesters />} />
          <Route
            path="/reset-password/:token"
            element={<ResetPassword />}
          ></Route>
        </Routes>
      </HashRouter>
    </div>
  );
}

export default App;
