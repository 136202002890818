import React, { useState } from "react";
import logo from "../assets/smallLogo.png";
import { Row, Col, Input, Checkbox, Button, Dropdown, Menu } from "antd";
import PropTypes from "prop-types";
import { login } from "../services/AuthService";
import { setToken } from "../services/TokenService";
import { Link, useNavigate } from "react-router-dom";
import { DownOutlined } from "@ant-design/icons/lib/icons";
import { useTranslation } from "react-i18next";

function Login() {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [remember, setRemember] = useState(false);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await login({
      username,
      password,
    });
    if (result.success === true) {
      setToken(result.result._id);

      localStorage.setItem("token", result.token);
      localStorage.setItem("userDetails", JSON.stringify(result.result));
      localStorage.setItem("user", result.result._id);
      if (result.result.avatar) {
        localStorage.setItem("avatar", result.result.avatar);
      } else {
        localStorage.removeItem("avatar");
      }

      if (remember) {
        localStorage.setItem("remember", "yes");
      }
      navigate("/");
      window.location.reload();
    } else {
      setError(t("forgotPassword.invalid"));
    }
  };

  const menu = (
    <Menu className="logout-menu">
      <Menu.Item key="1">
        <div
          className="logout"
          onClick={() => {
            i18n.changeLanguage("en");
            localStorage.setItem("language", "en");
          }}
        >
          {t("profilePage.english")}
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div
          className="logout"
          onClick={() => {
            i18n.changeLanguage("ko");
            localStorage.setItem("language", "ko");
          }}
        >
          {t("profilePage.korean")}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="Login"
      style={{
        height: "100vh",
        textAlign: "left",
      }}
    >
      <Row>
        <Col span={17} className="login-bg">
          <h1> {t("login.login-desc")}</h1>
        </Col>
        <Col span={7} className="login-form">
          <div>
            <div className="login-header">
              <img
                src={logo}
                alt="logo"
                data-src="/assets/img/cms/smallLogo.png"
                data-src-retina="/assets/img/cms/smallLogo.png"
                width="30"
                height="30"
              />
              <h4
                className="p-l-15 m-0"
                style={{ fontSize: "14px", paddingLeft: "15px" }}
              >
                CMS ADMIN
              </h4>
            </div>
            <h2>
              {t("login.hello")} <br />
              {t("login.welcome")}
            </h2>
            <p>{t("login.signinPrompt")}</p>

            <form
              id="form-login"
              role="form"
              onSubmit={handleSubmit}
              method="POST"
            >
              {error !== "" ? (
                <p style={{ marginBottom: "0px", color: "red" }}>{error}</p>
              ) : (
                ""
              )}

              <div className="controls">
                <Input
                  type="text"
                  name="username"
                  placeholder={t("profilePage.email")}
                  className="form-control"
                  required
                  onChange={(e) => setUserName(e.target.value)}
                />
              </div>

              <div className="controls">
                <Input
                  type="password"
                  className="form-control"
                  name="password"
                  placeholder={t("login.credentials")}
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
              </div>
              <Checkbox onChange={(e) => setRemember(!remember)}>
                {t("login.rememberMe")}
              </Checkbox>
              <Button type="primary search-btn signin-btn" htmlType="submit">
                {t("login.signIn")}
              </Button>

              <div className="login-links">
                <Link to="/forgot-password" className="normal">
                  {t("login.lostPassword")}
                </Link>
              </div>
            </form>
            <Dropdown overlay={menu} className="login-dropdown">
              <Button>
                {t("profilePage.language")} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <p className="trademark">
            {t("login.trademark")}
            {/* ©2019-2020 All Rights Reserved. Pages® is a registered trademark of
            Revox Ltd. <a href="">Cookie Policy</a>,{" "}
            <a href=""> Privacy and Terms</a>. */}
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default Login;
