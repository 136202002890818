import React, { useState, useEffect } from "react";
import logo from "../assets/smallLogo.png";
import { Row, Col, Dropdown, Button, Menu, Modal } from "antd";
import { DownOutlined } from "@ant-design/icons/lib/icons";
import { useParams } from "react-router";
import { validateToken, resetPassword } from "../services/AuthService";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function ResetPassword() {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(true);
  const [tokenValidated, setTokenValidated] = useState(false);
  const [privateToken, setPrivateToken] = useState("");
  const [passwordChangeSucces, setPasswordChangeSuccess] = useState(false);
  const [error, setError] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation("common");

  useEffect(() => {
    handleValidateToken();
  }, []);

  const handleValidateToken = async () => {
    let result = await validateToken(token);

    setTokenValidated(result.success);

    setTimeout(() => {
      if (result.success) {
        setPrivateToken(result.result);
      }
      setLoading(false);
    }, 2000);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let result = await resetPassword(privateToken, password, confirmPassword);

    setPasswordChangeSuccess(result.success);

    if (result.success) {
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else {
      setError(result.result);
    }
  };

  const menu = (
    <Menu className="logout-menu">
      <Menu.Item key="1">
        <div className="logout" onClick={() => i18n.changeLanguage("en")}>
          {t("profilePage.english")}
        </div>
      </Menu.Item>
      <Menu.Item key="1">
        <div className="logout" onClick={() => i18n.changeLanguage("ko")}>
          {t("profilePage.korean")}
        </div>
      </Menu.Item>
    </Menu>
  );

  return (
    <div
      className="Login"
      style={{
        height: "100vh",
        textAlign: "left",
      }}
    >
      <Row>
        <Col span={17} className="login-bg">
          <h1>Share your knowledge.</h1>
        </Col>
        <Col span={7} className="login-form">
          <div>
            <div className="login-header">
              <img
                src={logo}
                alt="logo"
                data-src="/assets/img/cms/smallLogo.png"
                data-src-retina="/assets/img/cms/smallLogo.png"
                width="30"
                height="30"
              />
              <h4 style={{ fontSize: "14px", paddingLeft: "15px" }}>
                CMS ADMIN
              </h4>
            </div>
            <h2> Reset Password</h2>
            <p>You can reset your password here</p>
            {loading ? (
              <p>Loading...</p>
            ) : (
              <>
                {tokenValidated ? (
                  <form
                    id="form-login"
                    className="p-t-15"
                    role="form"
                    style={{ width: "100%" }}
                    onSubmit={handleSubmit}
                    method="POST"
                  >
                    {error !== "" ? (
                      <p style={{ marginBottom: "0px", color: "red" }}>
                        {error}
                      </p>
                    ) : (
                      ""
                    )}
                    <div className="form-group form-group-default">
                      <label>New Password</label>
                      <div className="controls">
                        <input
                          type="password"
                          name="password"
                          placeholder="Credentials"
                          className="form-control"
                          required
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="form-group form-group-default">
                      <label>Confirm new password</label>
                      <div className="controls">
                        <input
                          type="password"
                          className="form-control"
                          name="password-confirm"
                          placeholder="Credentials"
                          required
                          onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                      </div>
                    </div>

                    <div className="d-flex-content-between">
                      <div className="d-flex align-items-center justify-content-end">
                        <input className="button" type="submit" value="Reset" />
                      </div>
                    </div>
                    <div>
                      <Link to="/login" className="normal">
                        Have an account? Sign in.
                      </Link>
                    </div>
                    <div>
                      <Link to="/register" className="normal">
                        Not a member yet? Signup now.
                      </Link>
                    </div>
                  </form>
                ) : (
                  <>
                    <h3 style={{ marginTop: 10 }}>Invalid Token</h3>
                    <p>
                      Your token has expired or does not exist, please try
                      again.
                    </p>
                  </>
                )}
              </>
            )}

            <Dropdown overlay={menu} className="login-dropdown">
              <Button>
                {t("profilePage.language")} <DownOutlined />
              </Button>
            </Dropdown>
          </div>
          <p className="trademark">
            {t("login.trademark")}
            {/* ©2019-2020 All Rights Reserved. Pages® is a registered trademark of
            Revox Ltd. <a href="">Cookie Policy</a>,{" "}
            <a href=""> Privacy and Terms</a>. */}
          </p>
        </Col>
      </Row>
      {/* START MODAL FOR PASSWORD CHANGE SUCCESS  */}
      <Modal
        show={passwordChangeSucces}
        hide={() => {
          setPasswordChangeSuccess(false);
        }}
        centered
      >
        <Modal.Body className="text-center">
          Password Changed, Please login again.
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default ResetPassword;
